const enabledOriginals = [
  '6Vp7RkJ7jfprakv8foXAra', // 1 A Runabout's Last Dance With The Twilight
  '2VSaq2yiNXhgtGxgVKf4YH', // 2 The Man Who Seeks Rhythm In Utopias
  '34o0b6q1gAIbWRsK81h5CS', // 3 Traffic Lines Flowing At Midnight
  '0AI8dCBfqYDHvK7UJwlF13', // 4 Angry Loneliness And The Last Cigarette In The Pack
  '3vxj3ILgWtI6YItzI1wMwf', // 5 Smoky Temple Songs Adorned With The Sands Of Time
  '44ueC3nwNrf8gb3irJAFEH', // 6 A Friendly American Bar
  '0WExVigHvAZiDcgjoQFIzK', // 7 Life Should Be Lived Like Women
  '0fZhRAfsyphWNARrUXP7Gl', // 8 Sentimental Sofa Chats
  '3CN8tO7RlExqgWaXOl0XT1', // 9 Adrien Brody's Daily Dose Of Sadness
  '1va7Y8NeNKeaPUAzrIUTjC', // 10 Dusty Travel Cassettes In The Glove Compartment
];

export const getEnabledOriginals = (playlists) => {
 return playlists.reduce((selectedPlaylists, playlist) => {
  if (enabledOriginals.includes(playlist.id)) {
    return [...selectedPlaylists, playlist];
  }
  return selectedPlaylists;
}, []);
};

