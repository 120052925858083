import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// Initialize Cloud Firestore through Firebase
firebase.initializeApp({
  apiKey: 'AIzaSyBeOZukYJWxi-C4-NIx1_Z2J-bHWzp_Sn8',
  authDomain: 'flowium-01.firebaseapp.com',
  projectId: 'flowium-01',
});

export const firebaseAuth = firebase.auth();

export const firestore = firebase.firestore();

if (process.env.REACT_APP_FLOWIUM_ENV === 'development') {
  firestore.settings({
    host: 'localhost:8080',
    ssl: false,
  });
}
