import { spotifyApiPending } from '../../spotifyApi';
import connectPlayer from './connectPlayer';
import transferPlayback from './transferPlayback';

const playURI = (playlistURI, trackURI = '') => (dispatch, getState) => {
  const { spotifyState, devices, isPlayerConnected } = getState().player;
  const deviceID = devices[0];
  let data = { context_uri: playlistURI };

  if (!deviceID) return;

  if (trackURI) {
    data = { ...data, offset: { uri: trackURI } };
  }

  if (!isPlayerConnected && !spotifyState) {
    return dispatch(connectPlayer())
      .then(() => dispatch(transferPlayback(deviceID, true)));
  }

  if (!spotifyState) {
    return dispatch(transferPlayback(deviceID, true));
  }

  return dispatch(
    spotifyApiPending({
      url: '/v1/me/player/play',
      method: 'put',
      data,
      params: { device_id: deviceID },
    }),
  );
};

export default playURI;
