import { APP_NAMES } from "../constants";

// auth
export const getAuthLoading = (state) => state.auth.loading;
export const getUser = (state) => state.auth.user;
export const getSpotifyUser = (state) => state.auth.spotifyUser;

// ui
export const getActiveTab = (state) => state.ui.activeTab;
export const getNextApp = (state) => state.ui.apps.nextApp;
export const getScreenType = (state) => state.ui.screenType;
export const getIsFullScreen = (state) => state.ui.isFullScreen;
export const getNotification = (state) => state.ui.notification;
export const getCurrentApp = (state) => state.ui.apps.currentApp;
export const getIsQuotBoxOpen = (state) => state.ui.isQuotBoxOpen;
export const getIsAccountBoxOpen = (state) => state.ui.isAccountBoxOpen;
export const getIsPlayButtonFixed = (state) => state.ui.isPlayButtonFixed;
export const getIsQuotBoxCollapsed = (state) => state.ui.isQuotBoxCollapsed;
export const getIsPlaylistTitleFixed = (state) => state.ui.isPlaylistTitleFixed;
export const getCurrentViewingPlaylistID = (state) => state.ui.currentViewingPlaylistID;
export const getCurrentViewingPlaylistURI = (state) => state.ui.currentViewingPlaylistURI;

// entities
export const getTracks = (state) => state.entities.tracks.data;
export const getOriginalsPlaylists = (state) => state.entities.playlists.originalsData;
export const getSelectionsPlaylists = (state) => state.entities.playlists.selectionsData;
export const getPlaylistsContentData = (state) => state.entities.playlistsContent.data;
export const getCurrentTracksData = (state) => {
  const { ui: { currentViewingPlaylistID }, entities: { tracks: { data } } } = state;
  return data[currentViewingPlaylistID]?.tracks?.items;
};
export const getArtists = (state) => state.entities.artists.data;

export const getCurrentPlaylistsData = (state) => {
  const {
    ui: { apps: { currentApp } },
    entities: { playlists: { originalsData, selectionsData } }
  } = state;
  if (currentApp === APP_NAMES.ORIGINALS) {
    return originalsData;
  }
  return selectionsData;
};

export const getSelectionsPlaylistTitle = (state) => {
  const playlistID = getCurrentViewingPlaylistID(state);
  const currentApp = getCurrentApp(state);
  if (currentApp === APP_NAMES.SELECTIONS) {
    const selectionsData = getSelectionsPlaylists(state);
    return selectionsData.find(playlist => playlistID === playlist.id)?.name || '';
  } else {
    const contents = getPlaylistsContentData(state);
    const currentContent = contents[playlistID] || {};
    return currentContent?.title || '';
  }
};

export const getArtwork = (state) => {
  const playlistID = getCurrentViewingPlaylistID(state);
  const currentApp = getCurrentApp(state);
  if (currentApp === APP_NAMES.SELECTIONS) {
    const artistsData = getArtists(state);
    const currentArtist = artistsData[playlistID] || {};
    const images = currentArtist?.images;
    return (images && images[0]?.url) || '';
  } else {
    const contents = getPlaylistsContentData(state);
    const currentContent = contents[playlistID] || {};
    return currentContent?.background || '';
  }
};

// player
export const getDevices = (state) => state.player.devices;
export const getSpotifyState = (state) => state.player.spotifyState;
export const getVolumePercent = (state) => state.player.volumePercent;
export const getIsCurrentTrackSaved = (state) => state.player.isCurentTrackSaved;
