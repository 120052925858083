/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'player',
  initialState: {
    devices: [],
    spotifyState: {},
    isSpotifyStateLoading: false,
    volumePercent: 50,
    isCurentTrackSaved: false,
    isCurentTrackSavedLoading: false,
    isPlayerConnected: false,
    error: {},
  },
  reducers: {
    playerConnected: (player) => {
      player.isPlayerConnected = true;
    },
    playerDisconnected: (player, action) => {
      player.isPlayerConnected = false;
    },
    deviceIdSet: (player, action) => {
      player.devices = [action.payload];
    },
    // available devices
    availableDevicesRequested: (player, action) => {},
    availableDevicesFulfilled: (player, action) => {
      const { devices } = action.payload;
      player.devices = devices;
      player.isSpotifyStateLoading = false;
    },
    availableDevicesRejected: (player, action) => {},
    // transfer playback state
    playbackTransferRequested: () => {},
    playbackTransferFulfilled: (player, action) => {
      
    },
    playbackTransferRejected: () => {},
    // playback state
    playerStateChanged: (player, action) => {
      player.spotifyState = action.payload;
      player.isSpotifyStateLoading = false;
    },
    // controls
    played: () => {},
    resumed: () => {},
    paused: () => {},
    prevPlayed: () => {},
    nextPlayed: () => {},
    shuffleToggled: () => {},
    repeatModeToggled: () => {},
    // volume
    initialVolumeRequested: () => {},
    initialVolumeReceived: (player, action) => {
      const { initialVolume } = action.payload;
      player.volumePercent = initialVolume;
    },
    volumeChanged: (player, action) => {
      const { volumePercent } = action.payload;
      player.volumePercent = parseFloat(volumePercent);
    },
    // get saved tracks
    trackSaveStatusRequested: (player) => {
      player.isCurentTrackSavedLoading = true;
    },
    trackSaveStatusFulfilled: (player, action) => {
      player.isCurentTrackSavedLoading = false;
      // eslint-disable-next-line prefer-destructuring
      player.isCurentTrackSaved = action.payload[0];
    },
    trackSaveStatusRejected: (player, action) => {
      player.isCurentTrackSavedLoading = false;
      if (action.payload.error) {
        player.error = action.payload.error;
      }
    },
    // save track
    trackSaveRequested: (player) => {
      player.isCurentTrackSavedLoading = true;
    },
    trackSaveFulfilled: (player) => {
      player.isCurentTrackSavedLoading = false;
      player.isCurentTrackSaved = true;
    },
    trackSaveRejected: (player, action) => {
      player.isCurentTrackSavedLoading = false;
      if (action.payload.error) {
        player.error = action.payload.error;
      }
    },
    // remove track
    trackRemoveRequested: (player) => {
      player.isCurentTrackSavedLoading = true;
    },
    trackRemoveFulfilled: (player) => {
      player.isCurentTrackSavedLoading = false;
      player.isCurentTrackSaved = false;
    },
    trackRemoveRejected: (player, action) => {
      player.isCurentTrackSavedLoading = false;
      if (action.payload.error) {
        player.error = action.payload.error;
      }
    },
    // errors
    initializationFailed: (player, action) => {
      player.error.message = action.message;
    },
    authenticationFailed: (player, action) => {
      player.error.message = action.message;
    },
    accountFailed: (player, action) => {
      player.error.message = action.message;
    },
    playbackFailed: (player, action) => {
      player.error.message = action.message;
    }
  },
});

export const {
  // connected
  playerConnected,
  playerDisconnected,
  // device id set
  deviceIdSet,
  // available devices
  availableDevicesRequested,
  availableDevicesFulfilled,
  availableDevicesRejected,
  // transfer playback
  playbackTransferRequested,
  playbackTransferFulfilled,
  playbackTransferRejected,
  // get playback state
  playerStateChanged,
  // controls
  played,
  resumed,
  paused,
  prevPlayed,
  nextPlayed,
  shuffleToggled,
  repeatModeToggled,
  // volume
  initialVolumeRequested,
  initialVolumeReceived,
  volumeChanged,
  // track save status
  trackSaveStatusRequested,
  trackSaveStatusFulfilled,
  trackSaveStatusRejected,
  // save track
  trackSaveRequested,
  trackSaveFulfilled,
  trackSaveRejected,
  // remove track
  trackRemoveRequested,
  trackRemoveFulfilled,
  trackRemoveRejected,
  // errors
  initializationFailed,
  authenticationFailed,
  accountFailed,
  playbackFailed
} = slice.actions;

export default slice.reducer;
