/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { spotifyApiPending } from '../spotifyApi';
import playURI from '../player/actionCreators/playURI';
import { getEnabledOriginals } from '../../utils/orginalsOpener';
import { fetchArtist, initArtistsMap } from './artists';
import { initCurrentViewingPlaylist } from '../ui/ui';

const slice = createSlice({
  name: 'playlists',
  initialState: {
    originalsData: [],
    selectionsData: [],
    loading: false,
    error: {},
  },
  reducers: {
    // originals
    playlistsOriginalsRequested: (playlists) => {
      playlists.loading = true;
    },
    playlistsOriginalsFulfilled: (playlists, action) => {
      playlists.loading = false;
      if (action.payload) {
        const enabledOriginalsPlaylists = getEnabledOriginals(action.payload.items);
        playlists.originalsData = enabledOriginalsPlaylists;
      }
    },
    playlistsOriginalsRejected: (playlists, action) => {
      playlists.loading = false;
      if (action.error) {
        playlists.error = action.error;
      }
    },
    // selections
    playlistSelectionsRequested: (playlists, action) => {
      playlists.loading = true;
    },
    playlistSelectionsFulfilled: (playlists, action) => {
      playlists.selectionsData.push(action.payload);
    },
    playlistSelectionsRejected: (playlists, action) => {
      playlists.loading = false;
      if (action.error) {
        playlists.error = action.error;
      }
    }
  }
});

export const {
  playlistsOriginalsRequested,
  playlistsOriginalsFulfilled,
  playlistsOriginalsRejected,
  playlistSelectionsRequested,
  playlistSelectionsFulfilled,
  playlistSelectionsRejected
} = slice.actions;

// orginals playlists
export const fetchOriginalsPlaylists = () => (dispatch, getState) => {
  const { playlists } = getState().entities;
  if (playlists.originalsData.length !== 0 || playlists.loading) return;

  return dispatch(
    spotifyApiPending({
      url: `/v1/users/e17g1fp2w5l9eit4fvhzwrhbf/playlists`,
      onPending: playlistsOriginalsRequested.type,
      onFulfilled: playlistsOriginalsFulfilled.type,
      onRejected: playlistsOriginalsRejected.type,
    }),
  );
};

const fetchSelectionsPlaylist = playlistID => (dispatch, getState) => {
  return dispatch(
    spotifyApiPending({
      url: `/v1/playlists/${playlistID}`,
      onPending: playlistSelectionsRequested.type,
      onFulfilled: playlistSelectionsFulfilled.type,
      onRejected: playlistSelectionsRejected.type,
    }),
  );
};

// selections playlists
export const fetchSelectionsPlaylists = () => (dispatch, getState) => {
  const { playlists } = getState().entities;
  if (playlists.selectionsData.length === 20 || playlists.loading) return;

  return dispatch(initArtistsMap())
    .then(() => {
      const artistMap = getState().entities.artists.artistMap;
      Object.keys(artistMap)
        .reduce((accPromise, nextPlaylistID, index) => {
        // fetch the first artist after the first playlist
        if (index === 0) {
          const firstArtistID = Object.values(artistMap)[0];
          return accPromise
            .then(() => dispatch(fetchSelectionsPlaylist(nextPlaylistID)))
            .then(() => dispatch(fetchArtist(firstArtistID)))
            .then(() => dispatch(initCurrentViewingPlaylist()))
        }
        // keep fetching remaining playlists
        return accPromise.then(() => dispatch(fetchSelectionsPlaylist(nextPlaylistID)))
    }, Promise.resolve());
  });
};

// play playlist
export const playPlaylist = (id, uri) => (dispatch) => {
  dispatch(playURI(uri));
};

export default slice.reducer;
