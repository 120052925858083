import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationContentEN } from '../../constants';
import { firebaseAuth } from '../../utils/firebase';
import { flowiumPlayer } from '../player/actionCreators/initPlayer';
import { createNotification } from '../ui/ui';

const signOutFlowium = createAsyncThunk(
  'auth/signOut',
  async (arg, { dispatch }) => {
    await firebaseAuth.signOut();
    flowiumPlayer.pause();
    dispatch(
      createNotification({ message: notificationContentEN.LOGOUT_SUCCEEDED }),
    );
  },
);

export default signOutFlowium;
