export const BREAKPOINTS = {
  XS_MAX: 575,
  SM_MIN: 576,
  MD_MIN: 768,
  LG_MIN: 992,
  XL_MIN: 1200,
  XXL_MIN: 1400,
};

export const SCREEN_TYPES = {
  XS_SCREEN: 'XS_SCREEN',
  SM_SCREEN: 'SM_SCREEN',
  MD_SCREEN: 'MD_SCREEN',
  LG_SCREEN: 'LG_SCREEN',
  XL_SCREEN: 'XL_SCREEN',
};

export const APP_NAMES = {
  ORIGINALS: 'ORIGINALS',
  SELECTIONS: 'SELECTIONS',
};

export const APP_SWITHCER = {
  [APP_NAMES.ORIGINALS]: APP_NAMES.SELECTIONS,
  [APP_NAMES.SELECTIONS]: APP_NAMES.ORIGINALS,
};

export const TABS = {
  PLAYLISTS: 'playlists',
  TRACKS: 'tracks'
};

export const notificationContentTR = {
  LOGIN_REQUIRED: 'Flowium\'un bu bölümünü kullanabilmek için Spotify Premium hesabın ile giriş yapmalısın.',
  LOGIN_SUCCEEDED: 'Hoşgeldin, umarız Flowium\'da keyifli vakit geçirirsin.',
  LOGOUT_SUCCEEDED: 'Güle güle, yine bekleriz.',
  PREMIUM_REQUIRED: 'Flowium\'da parça çalabilmek için Spotify hesabın Premium olmalı.',
  TRACK_LIKED: 'Parça, Spotify hesabının \'Beğenilen Şarkılar\' listesine eklendi.',
  TRACK_UNLIKED: 'Parça, Spotify hesabının \'Beğenilen Şarkılar\' listesinden kaldırıldı.',
  DEVICE_NOT_FOUND: 'Cihazın bulunamadı. Uygulama sayfasını yenilemek işe yarayabilir.',
  FORBIDDEN: 'Hay aksi, bu işlem için yetkin yok gibi görünüyor.',
  NOT_FOUND: 'Hay aksi, bir şeyler eksik, yanlış ya da bulunamadı.',
  GENERIC_ERROR: 'Hay aksi, bir şeyler ters gitti.',
};

export const notificationContentEN = {
  LOGIN_REQUIRED: 'Login required.',
  LOGIN_SUCCEEDED: 'Welcome to the Flowium.',
  LOGOUT_SUCCEEDED: 'See you later!',
  PREMIUM_REQUIRED: 'Sorry, you need a Premium Spotify account to use Flowium.',
  TRACK_LIKED: 'The track added to your Spotify Liked list.',
  TRACK_UNLIKED: 'The track removed from your Spotify Liked list.',
  DEVICE_NOT_FOUND: 'Oops, device not found.',
  FORBIDDEN: 'Oops, you are not authorized for this.',
  NOT_FOUND: 'Oops, something not found.',
  GENERIC_ERROR: 'Oops, something went wrong.',
};
