/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import signInFlowium from './signInFlowium';
import signOutFlowium from './signOutFlowium';
import fetchFlowiumUser from './fetchFlowiumUser';

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    spotifyUser: null,
    loading: true,
    error: {},
    isSpotifyUserFetched: false,
    isAsyncFlowInitialized: false
  },
  reducers: {
    refreshSpotifyTokenRequested: (auth, action) => {
      auth.loading = true;
    },
    refreshSpotifyTokenFulfilled: (auth, action) => {
      auth.loading = false;
      auth.user.access_token = action.payload.accessToken;
    },
    refreshSpotifyTokenRejected: (auth, action) => {
      auth.loading = false;
      auth.error = action.payload.error;
    },
    spotifyUserRequested: (auth) => {
      auth.loading = true;
    },
    spotifyUserFulfilled: (auth, action) => {
      auth.loading = false;
      auth.isSpotifyUserFetched = true;
      if (action.payload) {
        auth.spotifyUser = action.payload;
      }
    },
    spotifyUserRejected: (auth, action) => {
      auth.loading = false;
      if (action.error) {
        auth.error = action.error;
      }
    },
    initializeAsyncFlow: (auth, action) => {
      auth.isAsyncFlowInitialized = true;
    },
    userStatusChanged: (auth) => {
      auth.loading = false;
    }
  },
  extraReducers: {
    [signInFlowium.pending]: (state) => {
      state.loading = true;
    },
    [signInFlowium.rejected]: (state) => {
      state.loading = false;
    },
    [signInFlowium.fulfilled]: (state) => {
      state.loading = false;
    },
    [signOutFlowium.pending]: (state) => {
      state.loading = true;
    },
    [signOutFlowium.rejected]: (state, action) => {
      state.loading = false;
      if (action.error) {
        state.error = action.error;
      }
    },
    [signOutFlowium.fulfilled]: (state) => {
      state.user = null;
      state.loading = false;
    },
    [fetchFlowiumUser.pending]: (state) => {
      state.loading = true;
    },
    [fetchFlowiumUser.rejected]: (state, action) => {
      state.loading = false;
      if (action.error) {
        state.error = action.error;
      }
    },
    [fetchFlowiumUser.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.user = action.payload;
      }
    }
  },
});

export const {
  userStatusChanged,
  spotifyUserRequested,
  spotifyUserFulfilled,
  spotifyUserRejected,
  refreshSpotifyTokenRequested,
  refreshSpotifyTokenFulfilled,
  refreshSpotifyTokenRejected,
  initializeAsyncFlow
} = slice.actions;

export default slice.reducer;
