
const enabledSelections = [
  // 1 (Dua Lipa) Dua Lipa's Playlist
  {
    playlistID: '1NP7YSlu0KwlbazhvSLzSF',
    artistID: '6M2wZ9GZgrQXHCFfjv46we'
  }, 
  // 2 (Bruno Mars) 24K Magic + Singles 
  {
    playlistID: '4U71dLzGZ1886AfMqNlnJJ',
    artistID: '0du5cEVh5yTK9QJze8zA0C'
  }, 
  // 3 (Cold Play) Chris's playlist
  {
    playlistID: '1UBxTniRxpsQVvnXyNu4kh',
    artistID: '4gzpq5DPGxSnKTe4SA8HAU'
  }, 
  // 4 (Post Malone) posty's playlist
  {
    playlistID: '62aTmrLRUWaYSFGbrw23RJ',
    artistID: '246dkjvS1zLTtiykXe5h60'
  }, 
  // 5 (Halsey) Middle of Nowhere
  {
    playlistID: '13neF7PbRiWCIJcCn98h73',
    artistID: '26VFTg2z8YR0cCuwLzESi2'
  }, 
  // 6 (Chainsmokers) NICE HAIR by The Chainsmokers
  {
    playlistID: '2f59mTnTrRX7SZJvG4XkRC',
    artistID: '69GGBxA162lTqCwzJG5jLp'
  }, 
  // 7 (Charlie Puth) Charlie's Mixtape Favorites
  {
    playlistID: '4AW1vM2mf918pQ3Jav4pOy',
    artistID: '6VuMaDnrHyPL1p4EHjYLi7'
  }, 
  // 8 (Ellie Goulding) Music To Run To
  {
    playlistID: '5bbE23XkLc93OqxGeSPL16',
    artistID: '0X2BH1fck6amBIoJhDVmmJ'
  }, 
  // 9 (Clean Bandit) What Is... ?
  {
    playlistID: '1ygis0ABx5xgA2K2DdlKdm',
    artistID: '6MDME20pz9RveH9rEXvrOM'
  }, 
  // 10 (Panic At The Disco) Panic! At The Disco’s Raise Hell and Turn It Up Playlist ?
  {
    playlistID: '5zr2uJF8RQBU61UYOWcD9o',
    artistID: '20JZFwl6HVl6yg8a4H3ZqK'
  }, 
  // 11 (Lorde) Homemade Dynamite
  {
    playlistID: '43Yim9aRqJJ5Rmq5ag8XRJ',
    artistID: '163tK9Wjr9P9DmM0AVK7lm'
  }, 
  // 12 (Hozier) HOZIER PICKS
  {
    playlistID: '5bC5oIj1igsf5e5kJBNnr7',
    artistID: '2FXC3k01G6Gw61bmprjgqS'
  }, 
  // 13 (The Lumineers) The Lumineers Inspiration
  {
    playlistID: '7fY7cnOkVKU7HN9cN53Wwm',
    artistID: '16oZKvXb6WkQlVAjwo2Wbg'
  }, 
  // 14 (Muse) MUSE: Band's Picks
  {
    playlistID: '2zNu81WarotkZVGvMApl7y',
    artistID: '12Chz98pHFMPJEknJQMWvI'
  }, 
  // 15 (The 1975) At about 6 - 7
  {
    playlistID: '3ts1eb0lCuhPgATjU0gg7i',
    artistID: '3mIj9lX2MWuHmhNCA7LSCW'
  }, 
  // 16 (Rag’n’Bone Man) Travelling Playlist
  {
    playlistID: '4KfeGuzy4MLLRGG27DYtec',
    artistID: '4f9iBmdUOhQWeP7dcAn1pf'
  }, 
  // 17 (LP) Current Mood
  {
    playlistID: '7rj9VO0zLK3hLIZDOrYQB5',
    artistID: '0J7U24vlOOIeMpuaO6Q85A'
  }, 
  // 18 (Tom Misch) real good shit
  {
    playlistID: '5ryjXuoKfWH7WkXM3HAcFL',
    artistID: '1uiEZYehlNivdK3iQyAbye'
  }, 
  // 19 (Michael Kiwanuka) All Back To Michael's
  {
    playlistID: '6X5BskG2UIeK3vEKPPL33Z',
    artistID: '0bzfPKdbXL5ezYW2z3UGQj'
  }, 
  // 20 (In The Absence) In The Absence Thereof...v1
  {
    playlistID: '1krhv3xid5E7Z88ycPsvQb',
    artistID: '71hiZiat5t3E9H5oVA765d'
  }, 
];

// key: playlistID, value: artistID
export const createSelectionsArtistMap = () => {
  return enabledSelections.reduce(((selMap, selection) => {
    selMap[selection.playlistID] = selection.artistID;
    return selMap;
  }), {});
};

// key: artistID, value: playlistID
export const swapArtistMap = (artistMap) => {
  return Object.entries(artistMap).reduce(((swappedMap, [key, value]) => {
    swappedMap[value] = key;
    return swappedMap;
  }), {});
};
