import styled from 'styled-components';
import ScNoStyleButton from './ScNoStyleButton';

const ScIconWrapper = styled(ScNoStyleButton)`
  svg {
    ${({ width }) => width && `width: ${width};`}
    ${({ height }) => height && `height: ${height};`}
  }

  &:hover svg {
    ${({ widthHover }) => widthHover && `width: ${widthHover};`}
    ${({ heightHover }) => heightHover && `height: ${heightHover};`}
  }

  svg:not(#icon-repeat) {
    path,
    rect {
      ${({
    viewMode = 'fill',
    fill = '#bababa',
    stroke = '#bababa',
    activeFill = '#ffffff',
    activeStroke = '#ffffff',
    isActive = false,
  }) => {
    if (viewMode === 'fill') {
      return `fill: ${fill};
              ${isActive ? `fill: ${activeFill}` : ''};
              stroke: none;`;
    }

    if (viewMode === 'stroke') {
      return `stroke: ${stroke};
              ${isActive ? `stroke: ${activeStroke}` : ''};
              fill: none;`;
    }

    return null;
  }}
    }
  }

  &:hover svg#icon-repeat,
  &:hover svg:not(#icon-repeat) {
    path,
    rect {
      ${({
    viewMode = 'fill',
    hoverFill = '#ffffff',
    hoverStroke = '#ffffff',
    activeFillHover = '#ffffff',
    activeStrokeHover = '#ffffff',
    isActive = false,
  }) => {
    if (viewMode === 'fill') {
      return `fill: ${hoverFill};
              ${isActive ? `fill: ${activeFillHover}` : ''};
              stroke: none;`;
    }

    if (viewMode === 'stroke') {
      return `stroke: ${hoverStroke};
              ${isActive ? `stroke: ${activeStrokeHover}` : ''};
              fill: none;`;
    }

    return null;
  }}
    }
  }
`;

export default ScIconWrapper;
