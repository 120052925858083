import { playerConnected } from '../player';
import { flowiumPlayer } from './initPlayer';

const connectPlayer = () => (dispatch, getState) => {
  return flowiumPlayer.connect().then(success => {
    if (success) {
      return dispatch(playerConnected());
    }
  });
};

export default connectPlayer;
