/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { spotifyApiPending } from '../spotifyApi';
import playURI from '../player/actionCreators/playURI';

export const fetchTracksFromFlowium = createAsyncThunk(
  'playlists/fetchFromFlowium',
  async () => {},
);

const slice = createSlice({
  name: 'tracks',
  initialState: {
    data: {},
    loading: false,
    error: {},
  },
  reducers: {
    tracksRequested: (tracks) => {
      tracks.loading = true;
    },
    tracksFulfilled: (tracks, action) => {
      tracks.loading = false;
      if (action.payload) {
        tracks.data[action.payload.id] = action.payload;
      }
    },
    tracksRejected: (tracks, action) => {
      tracks.loading = false;
      if (action.error) {
        tracks.error = action.error;
      }
    },
  },
});

export const {
  tracksRequested,
  tracksFulfilled,
  tracksRejected,
} = slice.actions;

export default slice.reducer;

// get tracks
export const fetchTracks = () => (dispatch, getState) => {
  const { tracks } = getState().entities;
  const { currentViewingPlaylistID } = getState().ui;

  if (tracks.loading) return;

  return dispatch(
    spotifyApiPending({
      url: `/v1/playlists/${currentViewingPlaylistID}`,
      onPending: tracksRequested.type,
      onFulfilled: tracksFulfilled.type,
      onRejected: tracksRejected.type,
    }),
  );
};

// play track
export const playTrack = (trackURI) => (dispatch, getState) => {
  const playlistURI = getState().ui.currentViewingPlaylistURI;
  return dispatch(playURI(playlistURI, trackURI));
};
