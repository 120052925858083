/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchTracks } from '../entities/tracks';
import {
  BREAKPOINTS,
  SCREEN_TYPES,
  APP_NAMES,
  APP_SWITHCER,
  TABS,
} from '../../constants';
import { fetchArtist } from '../entities/artists';

const slice = createSlice({
  name: 'ui',
  initialState: {
    activeTab: TABS.PLAYLISTS,
    currentViewingPlaylistID: null,
    currentViewingPlaylistURI: null,
    notification: {},
    isAccountBoxOpen: false,
    screenType: SCREEN_TYPES.XL_SCREEN,
    apps: {
      currentApp: APP_NAMES.ORIGINALS,
      nextApp: APP_NAMES.SELECTIONS,
    },
    isPlaylistTitleFixed: false,
    isPlayButtonFixed: false,
    isQuotBoxCollapsed: false,
    isFullScreen: false
  },
  reducers: {
    tabChanged: (ui, action) => {
      ui.activeTab = action.payload.tabKey;
    },
    playlistChanged: (ui, action) => {
      ui.currentViewingPlaylistID = action.payload.id;
      ui.currentViewingPlaylistURI = action.payload.uri;
    },
    notificationCreated: (ui, action) => {
      ui.notification = { ...action.payload };
    },
    accountBoxToggled: (ui, action) => {
      ui.isAccountBoxOpen = action.payload.isAccountBoxOpen;
    },
    appToggled: (ui, action) => {
      ui.apps.currentApp = action.payload.currentApp;
      ui.apps.nextApp = action.payload.nextApp;
    },
    quotBoxCollapseToggled: (ui, action) => {
      ui.isQuotBoxCollapsed = action.payload.isQuotBoxCollapsed;
    },
    playlistTitlePositionChanged: (ui, action) => {
      ui.isPlaylistTitleFixed = action.payload.isPlaylistTitleFixed;
    },
    playButtonPositionChanged: (ui, action) => {
      ui.isPlayButtonFixed = action.payload.isPlayButtonFixed;
    },
    screenTypeChanged: (ui, action) => {
      const { SM_MIN, MD_MIN, LG_MIN } = BREAKPOINTS;
      const {
        XS_SCREEN, SM_SCREEN, MD_SCREEN, LG_SCREEN,
      } = SCREEN_TYPES;

      const { screenSize } = action.payload;

      switch (true) {
        case screenSize < SM_MIN:
          ui.screenType = XS_SCREEN;
          break;
        case screenSize < MD_MIN:
          ui.screenType = SM_SCREEN;
          break;
        case screenSize < LG_MIN:
          ui.screenType = MD_SCREEN;
          break;
        case screenSize >= LG_MIN:
          ui.screenType = LG_SCREEN;
          break;
        default:
          ui.screenType = LG_SCREEN;
      }
    },
    fullScreenToggled: (player) => {
      player.isFullScreen = !player.isFullScreen;
    },
  },
});

export const {
  tabChanged,
  playlistChanged,
  notificationCreated,
  accountBoxToggled,
  appToggled,
  screenTypeChanged,
  playlistTitlePositionChanged,
  playButtonPositionChanged,
  quotBoxCollapseToggled,
  fullScreenToggled
} = slice.actions;

// init currently viewing playlist
export const initCurrentViewingPlaylist = () => (dispatch, getState) => {
  const { playlists } = getState().entities;
  const currentApp = getState().ui.apps.currentApp;
  const isCurrentAppOriginals = APP_NAMES.ORIGINALS === currentApp;

  if (isCurrentAppOriginals && playlists.originalsData.length === 0) return;
  if (!isCurrentAppOriginals && playlists.selectionsData.length === 0) return;

  const firstPlaylist = isCurrentAppOriginals ? playlists.originalsData[0] : playlists.selectionsData[0];
  const { id } = firstPlaylist;
  const { uri } = firstPlaylist;

  return dispatch(changePlaylist(id, uri));
};

// change playlist
export const changePlaylist = (id, uri) => (dispatch, getState) => {
  const { tracks } = getState().entities;
  const { auth } = getState();
  const { currentApp } = getState().ui.apps;

  // change the playlist
  return dispatch(playlistChanged({ id, uri }))
      // fetch tracks data if it doesnt exists
      .then(() => {
        if (!tracks.data[id] && auth.user) {
          return dispatch(fetchTracks())
        }
        return Promise.resolve();
      })
      // fetch artist data if the app is selections and the data doesnt exists
      .then(() => {
        if (currentApp === APP_NAMES.SELECTIONS) {
          const artistMap = getState().entities.artists.artistMap;
          const afterTheFirstArtist = Object.keys(artistMap).length > 0;
          const isArtistExists = getState().entities.artists.data[id];
          if (afterTheFirstArtist && !isArtistExists) {
            const artistID = artistMap[id];
            return dispatch(fetchArtist(artistID));
          }
        }
        return Promise.resolve();
      });
};

// change tab
export const changeTab = (tabKey) => (dispatch) => dispatch(tabChanged({ tabKey }));

// toggle account box
export const toggleAccountBox = () => (dispatch, getState) => {
  const { isAccountBoxOpen } = getState().ui;
  return dispatch(accountBoxToggled({ isAccountBoxOpen: !isAccountBoxOpen }));
};

// toggle QuotBox collapse
export const toggleQuotBox = () => (dispatch, getState) => {
  const { isQuotBoxCollapsed } = getState().ui;
  return dispatch(quotBoxCollapseToggled({ isQuotBoxCollapsed: !isQuotBoxCollapsed }));
};

export const collapseQuotBox = () => (dispatch) => {
  return dispatch(quotBoxCollapseToggled({ isQuotBoxCollapsed: true }));
};

export const showQuotBox = () => (dispatch) => {
  return dispatch(quotBoxCollapseToggled({ isQuotBoxCollapsed: false }));
};

// toggle app
export const toggleApp = () => (dispatch, getState) => {
  const { currentApp } = getState().ui.apps;
  const { nextApp } = getState().ui.apps;
  return dispatch(appToggled({
    currentApp: APP_SWITHCER[currentApp],
    nextApp: APP_SWITHCER[nextApp],
  }));
};

// change screen type
export const changeScreenType = (screenSize) => (dispatch) => {
  dispatch(screenTypeChanged({ screenSize }));
};

// change playlist title position
export const changePlaylistTitlePosition = (isPlaylistTitleFixed) => (dispatch) => {
  dispatch(playlistTitlePositionChanged({ isPlaylistTitleFixed }));
};

// change play button position
export const changePlayButtonPosition = (isPlayButtonFixed) => (dispatch) => {
  dispatch(playButtonPositionChanged({ isPlayButtonFixed }));
};

// create notification
export const createNotification = ({ message, options = {} }) => (dispatch) => {
  dispatch(notificationCreated({ message, options }));
};

export default slice.reducer;
