import { createAsyncThunk } from '@reduxjs/toolkit';
import { firebaseAuth } from '../../utils/firebase';

const signInFlowium = createAsyncThunk(
  'auth/signIn',
  async ({ status, sessionToken, error }) => {
    if (status === 'login-succeeded' && sessionToken) {
      await firebaseAuth.signInWithCustomToken(sessionToken);
    } else if (status === 'login-failed') {
      throw error || '[Flowium-Error] - Spotify login failed!';
    }
  },
);

export default signInFlowium;
