import { createNotification } from '../../ui/ui';
import {
  initializationFailed,
  authenticationFailed,
  accountFailed,
  playbackFailed,
  playerConnected,
  playerDisconnected,
  playerStateChanged,
  deviceIdSet
} from '../player';
import { getCurrentTrackSave } from './manageSavedTracks';
import getInitialVolume from './getInitialVolume';
import transferPlayback from './transferPlayback';
import { refreshSpotifyTokenRequested } from '../../auth/auth';
import { notificationContentEN } from '../../../constants';

export let flowiumPlayer = {};

// init flowium player
const initPlayer = () => (dispatch, getState) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://sdk.scdn.co/spotify-player.js';
    script.async = true;
    document.body.appendChild(script);

    // on spotify web playback sdk ready
    global.onSpotifyWebPlaybackSDKReady = () => {
      flowiumPlayer = new global.Spotify.Player({
        name: 'Flowium',
        getOAuthToken: async (cb) => {
          console.log('getOAuthToken');
          const isPlayerConnected = getState().player.isPlayerConnected;

          // connecting first time
          if (!isPlayerConnected) {
            const accessToken = getState().auth.user.access_token;
            cb(accessToken);
          } else {
            dispatch(refreshSpotifyTokenRequested()).then(
              ({ payload: { accessToken } }) => {
                cb(accessToken);
              }
            );
          }
        },
      });

      flowiumPlayer.addListener('ready', async ({ device_id: deviceID }) => {
        console.log('ready with deviceID', deviceID);
        dispatch(transferPlayback(deviceID))
        .then(dispatch(deviceIdSet(deviceID)))
        .then(() => dispatch(getCurrentTrackSave()))
        .then(() => dispatch(getInitialVolume()))
        .then(() => resolve());
        console.log('init playback resolve...');
      });

      flowiumPlayer.addListener('not_ready', ({ device_id: deviceID }) => {
        console.log('player is not ready...', deviceID);
      });

      flowiumPlayer.addListener('player_state_changed', (spotifyState) => {
        dispatch(playerStateChanged(spotifyState));
        // if (!isEmpty(spotifyState)) {
        //   dispatch(getCurrentTrackSave(spotifyState))
        // }
      });

      // // error handling
      flowiumPlayer.addListener('initialization_error', ({ message }) => {
        dispatch(initializationFailed({ message }));
      });

      flowiumPlayer.addListener('authentication_error', ({ message }) => {
        dispatch(authenticationFailed({ message }));
        if (!getState().auth.loading) {
          dispatch(refreshSpotifyTokenRequested());
        }
      });

      flowiumPlayer.addListener('account_error', ({ message }) => {
        dispatch(accountFailed({ message }));
        dispatch(
          createNotification({
            message: notificationContentEN.PREMIUM_REQUIRED,
            options: { autoClose: false },
          })
        );
      });

      flowiumPlayer.addListener('playback_error', ({ message }) => {
        dispatch(playbackFailed({ message }));
      });

      // connect
      flowiumPlayer.connect().then((success) => {
        if (success) {
          console.log('connected');
          dispatch(playerConnected());
        } else {
          dispatch(playerDisconnected());
        }
      });
    };
  });
};

export default initPlayer;
