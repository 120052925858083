import styled from 'styled-components';

const ScNoStyleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

export default ScNoStyleButton;
