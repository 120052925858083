import { createSlice } from '@reduxjs/toolkit';
import {createSelectionsArtistMap, swapArtistMap} from '../../utils/createSelectionsArtistMap';
import { spotifyApiPending } from '../spotifyApi';

const slice = createSlice({
  name: 'artists',
  initialState: {
    data: {},
    artistMap: {},
    swappedArtistMap: {},
    loading: false,
    error: {},
  },
  reducers: {
    artistsMapInitialized: (artists, action) => {
      artists.artistMap = action.payload;
    },
    swappedArtistMapInitialized: (artists, action) => {
      artists.swappedArtistMap = action.payload;
    },
    artistRequested: (artists, action) => {
      artists.loading = true;
    },
    artistFulfilled: (artists, action) => {
      const { id: artistID } = action.payload;
      const playlistID = artists.swappedArtistMap[artistID];
      artists.data[playlistID] = action.payload;
    },
    artistRejected: (artists, action) => {
      artists.loading = false;
      if (action.error) {
        artists.error = action.error;
      }
    }
  }
});

export const {
  artistsMapInitialized,
  swappedArtistMapInitialized,
  artistRequested,
  artistFulfilled,
  artistRejected
} = slice.actions;

export const fetchArtist = artistID => (dispatch, getState) => {
  return dispatch(
    spotifyApiPending({
      url: `/v1/artists/${artistID}`,
      onPending: artistRequested.type,
      onFulfilled: artistFulfilled.type,
      onRejected: artistRejected.type,
    }),
  );
};

export const initArtistsMap = () => (dispatch, getState) => {
  const artistMap = createSelectionsArtistMap();
  const swappedArtistMap = swapArtistMap(artistMap);
  return dispatch(artistsMapInitialized(artistMap))
   .then(() => dispatch(swappedArtistMapInitialized(swappedArtistMap)))
};

export default slice.reducer;